import {
  Box,
  VStack,
  HStack,
  Divider,
  Button,
  Text,
  Container,
  Flex,
  Grid,
  useColorModeValue,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Heading,
  Icon,
  Skeleton,
} from "@chakra-ui/react";
import {
  FaRegCalendarTimes,
  FaLock,
  FaUserShield,
  FaCalendarAlt,
} from "react-icons/fa";
import { useState, useEffect } from "react";
import SidebarItems from "../components/Sidebar/SidebarItems";
import useAuthStore from "../store/authStore";
import { useNavigate } from "react-router-dom";
import useFetchInvitations from "../hooks/useFetchInvitations";
import InvitationItem from "../components/InvitationItem";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase/firebase";
import PageLayout from "../Layouts/PageLayout/PageLayout";

const InvitationsPage = () => {
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const userId = authUser?.id;

  const { invitations, loading, error } = useFetchInvitations(userId);
  const [filter, setFilter] = useState("upcoming");

  // Colors and styles
  const textColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const bgColor = useColorModeValue("white", "gray.900");
  const sectionBgColor = useColorModeValue("white", "gray.800");
  const subtitleColor = useColorModeValue("gray.600", "gray.400");
  const dividerColor = useColorModeValue("gray.300", "gray.600");
  const accentColor = useColorModeValue("#007AFF", "#0A84FF");

  const [loadingz, setLoadingz] = useState(false);
  const [message, setMessage] = useState("");
  const toast = useToast();

  const filteredInvitations =
    filter === "upcoming"
      ? invitations.filter(
          (invite) => new Date(invite.createdAt.toDate()) > new Date()
        )
      : invitations.filter(
          (invite) => new Date(invite.createdAt.toDate()) <= new Date()
        );

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: "touch",
        }}
      >
        <Container
          maxW={{ base: "container.md", md: "container.xl" }}
          px={4}
          py={6}
        >
          {/* Header */}
          <Flex
            w="full"
            alignItems="center"
            justify={{ base: "space-between", md: "center" }}
            mb={6}
            gap={4}
          >
            {/* Logo */}
            <Box
              as="h1"
              fontSize={{ base: "26px", md: "32px" }}
              fontWeight="900"
              letterSpacing="-0.03em"
              position="relative"
              display={{ base: "inline-block", md: "none" }}
              transform="translateY(-1px)"
              cursor="pointer"
              onClick={() => navigate("/")}
              transition="all 0.3s ease"
              _hover={{
                transform: "translateY(-2px) scale(1.02)",
              }}
            >
              <Box
                as="span"
                position="relative"
                bgGradient="linear(135deg, #4F46E5 0%, #7C3AED 100%)"
                bgClip="text"
                _before={{
                  content: '""',
                  position: "absolute",
                  left: "-6px",
                  right: "-6px",
                  top: "-6px",
                  bottom: "-6px",
                  background:
                    "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
                  opacity: 0.15,
                  borderRadius: "xl",
                  transform: "rotate(-2deg)",
                  transition: "all 0.3s ease",
                  zIndex: -1,
                }}
                _after={{
                  content: '""',
                  position: "absolute",
                  left: "-3px",
                  right: "-3px",
                  top: "-3px",
                  bottom: "-3px",
                  background: "white",
                  opacity: 0.9,
                  borderRadius: "lg",
                  transform: "rotate(-1deg)",
                  transition: "all 0.3s ease",
                  zIndex: -1,
                }}
              >
                hubzo
              </Box>
            </Box>

            {/* Filter Pills - Desktop centered, Mobile to the right */}
            <HStack spacing={2} justify={{ base: "flex-end", md: "center" }}>
              <Button
                size="sm"
                bg={
                  filter === "upcoming"
                    ? "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                    : "white"
                }
                color={filter === "upcoming" ? "white" : "gray.700"}
                borderWidth={1}
                borderColor={filter === "upcoming" ? "transparent" : "gray.200"}
                borderRadius="full"
                onClick={() => setFilter("upcoming")}
                _hover={{
                  transform: "translateY(-1px)",
                  shadow: "sm",
                }}
                transition="all 0.2s"
                px={4}
              >
                Upcoming
              </Button>
              <Button
                size="sm"
                bg={
                  filter === "past"
                    ? "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                    : "white"
                }
                color={filter === "past" ? "white" : "gray.700"}
                borderWidth={1}
                borderColor={filter === "past" ? "transparent" : "gray.200"}
                borderRadius="full"
                onClick={() => setFilter("past")}
                _hover={{
                  transform: "translateY(-1px)",
                  shadow: "sm",
                }}
                transition="all 0.2s"
                px={4}
              >
                Past
              </Button>
            </HStack>
          </Flex>

          {/* Invitations Grid/List */}
          {loading ? (
            <Grid
              templateColumns={{
                base: "1fr",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={6}
              w="full"
              mb={8}
            >
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} height="400px" borderRadius="xl" />
              ))}
            </Grid>
          ) : filteredInvitations.length > 0 ? (
            <Grid
              templateColumns={{
                base: "1fr",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={6}
              w="full"
              mb={8}
            >
              {filteredInvitations.map((invitation) => (
                <InvitationItem key={invitation.id} invitation={invitation} />
              ))}
            </Grid>
          ) : (
            !authUser?.isCreator && (
              <Box
                bg="white"
                borderRadius="2xl"
                w="full"
                maxW={{ base: "full", md: "lg" }}
                mx="auto"
                overflow="hidden"
                borderWidth="1px"
                borderColor="gray.200"
                shadow="sm"
                mb={8}
                transition="all 0.2s"
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "md",
                }}
              >
                <Box bg="gray.50" py={2} px={4}>
                  <Text
                    fontSize={{ base: "sm", md: "sm" }}
                    color="gray.700"
                    fontWeight="bold"
                  >
                    ⌛️ NO INVITES YET
                  </Text>
                </Box>

                <Box p={{ base: 8, md: 8 }}>
                  <VStack
                    spacing={{ base: 6, md: 6 }}
                    align="center"
                    textAlign="center"
                  >
                    <Box p={4} bg="purple.50" rounded="full" color="purple.500">
                      <FaRegCalendarTimes size={30} />
                    </Box>
                    <VStack spacing={3}>
                      <Text
                        fontWeight="bold"
                        fontSize={{ base: "xl", md: "xl" }}
                        color="gray.800"
                      >
                        No Invites Yet
                      </Text>
                      <Text
                        fontSize={{ base: "md", md: "md" }}
                        color="gray.600"
                        lineHeight="tall"
                        maxW="md"
                      >
                        Complete your profile to increase your chances of
                        receiving invites to exclusive events ✨
                      </Text>
                    </VStack>

                    <Button
                      size="lg"
                      bg="gray.900"
                      color="white"
                      px={8}
                      h={14}
                      fontSize="lg"
                      fontWeight="semibold"
                      rounded="full"
                      _hover={{
                        transform: "translateY(-2px)",
                        shadow: "lg",
                        opacity: 0.95,
                      }}
                      transition="all 0.2s"
                      onClick={() => navigate("/profile_score")}
                    >
                      Complete Your Profile
                    </Button>
                  </VStack>
                </Box>
              </Box>
            )
          )}

          {/* Advice Box */}
          {!loading && !authUser?.isCreator && false && (
            <Box
              bg="white"
              borderRadius="2xl"
              w="full"
              maxW={{ base: "full", md: "lg" }}
              mx="auto"
              overflow="hidden"
              borderWidth="1px"
              borderColor="gray.200"
              shadow="sm"
              transition="all 0.2s"
              _hover={{
                transform: "translateY(-2px)",
                shadow: "md",
              }}
            >
              <Box bg="purple.50" py={2} px={4}>
                <Text
                  fontSize={{ base: "sm", md: "sm" }}
                  color="purple.700"
                  fontWeight="bold"
                >
                  ✨ UNLOCK MORE OPPORTUNITIES
                </Text>
              </Box>

              <Box p={{ base: 8, md: 8 }}>
                <VStack
                  spacing={{ base: 6, md: 6 }}
                  align="center"
                  textAlign="center"
                >
                  <Box p={4} bg="purple.50" rounded="full" color="gray.500">
                    <Icon as={FaUserShield} boxSize={6} />
                  </Box>
                  <VStack spacing={3}>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "xl", md: "xl" }}
                      color="gray.800"
                    >
                      Want More Invites? 🎉
                    </Text>
                    <Text
                      fontSize={{ base: "md", md: "md" }}
                      color="gray.600"
                      lineHeight="tall"
                      maxW="md"
                    >
                      Verify your profile to unlock exclusive events and
                      experiences
                    </Text>
                  </VStack>

                  <Button
                    size="lg"
                    bg="gray.900"
                    color="white"
                    px={8}
                    h={14}
                    fontSize="lg"
                    fontWeight="semibold"
                    rounded="full"
                    _hover={{
                      transform: "translateY(-2px)",
                      shadow: "lg",
                      opacity: 0.95,
                    }}
                    transition="all 0.2s"
                    onClick={() => navigate("/profile_score")}
                  >
                    Boost Profile ⭐️
                  </Button>
                </VStack>
              </Box>
            </Box>
          )}

          {authUser?.isCreator && (
            <Box
              bg="white"
              borderRadius="2xl"
              w="full"
              maxW={{ base: "full", md: "lg" }}
              mx="auto"
              overflow="hidden"
              borderWidth="1px"
              borderColor="gray.200"
              shadow="sm"
              transition="all 0.2s"
              _hover={{
                transform: "translateY(-2px)",
                shadow: "md",
              }}
              mt={8}
            >
              <Box bg="purple.50" py={2} px={4}>
                <Text
                  fontSize={{ base: "sm", md: "sm" }}
                  color="purple.700"
                  fontWeight="bold"
                >
                  🎯 FOR EVENT ORGANIZERS
                </Text>
              </Box>

              <Box p={{ base: 8, md: 8 }}>
                <VStack
                  spacing={{ base: 6, md: 6 }}
                  align="center"
                  textAlign="center"
                >
                  <Box p={4} bg="purple.50" rounded="full" color="gray.600">
                    <Icon as={FaCalendarAlt} boxSize={6} />
                  </Box>
                  <VStack spacing={3}>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "xl", md: "xl" }}
                      color="gray.800"
                    >
                      Ready to Host an Event? 🎉
                    </Text>
                    <Text
                      fontSize={{ base: "md", md: "md" }}
                      color="gray.600"
                      lineHeight="tall"
                      maxW="md"
                    >
                      Create an exclusive event and connect with amazing people
                    </Text>
                  </VStack>

                  <Button
                    size="lg"
                    bg="gray.900"
                    color="white"
                    px={8}
                    h={14}
                    fontSize="lg"
                    fontWeight="semibold"
                    rounded="full"
                    _hover={{
                      transform: "translateY(-2px)",
                      shadow: "lg",
                      opacity: 0.95,
                    }}
                    transition="all 0.2s"
                    onClick={() => navigate("/create_event_title")}
                  >
                    Create Event ✨
                  </Button>
                </VStack>
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default InvitationsPage;
