import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  Image,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePreviewImg from "../../hooks/usePreviewImg";
import useUpdateFacePic from "../../hooks/useUpdateFacePic";
import useShowToast from "../../hooks/useShowToast";
import useAuthStore from "../../store/authStore";
import DesktopNav from "../../components/Navigation/DesktopNav";

const UpdateFacePicPage = () => {
  const authUser = useAuthStore((state) => state.user);
  const showToast = useShowToast();
  const fileRef = useRef(null);
  const { handleImageChange, selectedFile, setSelectedFile } = usePreviewImg();
  const { isUpdating, editProfile } = useUpdateFacePic();
  const navigate = useNavigate();

  const colors = {
    textColor: "gray.800",
    bgColor: "white",
    containerBg: "white",
    buttonBg: "blue.500",
    buttonHover: "blue.600",
    accentColor: "#007AFF",
    borderColor: "gray.200",
    subtitleColor: "gray.500",
  };

  const handleEditProfile = async () => {
    if (!selectedFile) {
      showToast("Error", "Your picture cannot be empty", "error");
    } else {
      try {
        await editProfile(selectedFile);
        setSelectedFile(null);
        navigate(-1);
      } catch (error) {
        showToast("Error", error.message, "error");
      }
    }
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box display={{ base: "none", md: "block" }}>
        <DesktopNav />
      </Box>

      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
          mt={{ base: 0, md: "60px" }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={6}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              display={{ base: "flex", md: "none" }}
              color={colors.accentColor}
            />
            <Text 
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="bold"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
            >
              Choose Your Picture
            </Text>
          </Flex>

          {/* Main Content */}
          <Box maxW={{ base: "full", md: "lg" }} mx="auto">
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.dividerColor}
              p={6}
              textAlign="center"
            >
              <VStack spacing={6} align="center">
                <Text 
                  fontSize={{ base: "sm", md: "md" }} 
                  color={colors.subtitleColor} 
                  maxW="md"
                  lineHeight="tall"
                >
                  Clear face-and-body photos boost your profile for exclusive invites.
                  Photos lock for 3 months after verification.
                </Text>

                {/* Image Upload Box */}
                <FormControl>
                  <Box
                    w="full"
                    h={{ base: "72", md: "96" }}
                    borderWidth="2px"
                    borderStyle="dashed"
                    borderColor={colors.borderColor}
                    borderRadius="xl"
                    bg="gray.50"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => fileRef.current.click()}
                    overflow="hidden"
                    position="relative"
                    transition="all 0.2s"
                    _hover={{
                      borderColor: colors.accentColor,
                      shadow: "md"
                    }}
                  >
                    <Image
                      src={selectedFile || authUser?.profilePicURL}
                      alt="Selected Profile"
                      objectFit="cover"
                      w="full"
                      h="full"
                      fallback={
                        <Text color={colors.subtitleColor}>
                          Click to upload image
                        </Text>
                      }
                    />
                  </Box>
                  <Input
                    type="file"
                    hidden
                    ref={fileRef}
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </FormControl>

                {/* Update Button */}
                <Button
                  w="full"
                  maxW="400px"
                  bg={selectedFile ? colors.buttonBg : "gray.300"}
                  color="white"
                  size="lg"
                  h={{ base: 12, md: 14 }}
                  fontSize={{ base: "md", md: "lg" }}
                  isLoading={isUpdating}
                  onClick={handleEditProfile}
                  rounded="full"
                  isDisabled={!selectedFile}
                  _hover={{ 
                    bg: selectedFile ? colors.buttonHover : "gray.300",
                    transform: selectedFile ? "translateY(-1px)" : "none",
                    shadow: selectedFile ? "md" : "none",
                  }}
                  transition="all 0.2s"
                >
                  Update
                </Button>
              </VStack>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default UpdateFacePicPage;
