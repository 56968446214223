import {
  Container,
  Flex,
  Text,
  Box,
  Divider,
  Stack,
  HStack,
  VStack,
  IconButton,
  SkeletonText,
  CircularProgress,
  Tooltip,
  Avatar,
} from "@chakra-ui/react";
import { ChevronRightIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { FaCheckCircle, FaExclamationCircle, FaAward } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import useGetUserProfileById from "../../hooks/useGetUserProfileById";

const ProfileStrengthPage = () => {
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const { isLoading, userProfile } = useGetUserProfileById(authUser?.uid);

  const colors = {
    textColor: "gray.800",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "gray.100",
    hoverBgColor: "gray.50",
    subtitleColor: "gray.600",
    accentColor: "blue.500",
    highlightColor: "blue.500",
    alertIconColor: "orange.500",
    descriptionColor: "gray.600",
  };

  const isProfileComplete =
    userProfile?.instagram &&
    userProfile?.interests &&
    userProfile?.job &&
    userProfile?.yob &&
    userProfile?.linkedin;

  const profileItems = [
    {
      label: "Verified Profile",
      description: "Get verified at your first meetup.",
      field: "verified",
      navigateTo: userProfile?.fullName === "zec" ? "/users_list" : null,
    },
    {
      label: "Profile Picture",
      description: "Help us match you to the right meetups.",
      field: "profilePicURL",
      navigateTo: "/update_face_pic",
    },
    {
      label: "Profile Completion",
      description: "More details = better meetup matches.",
      field: "profileCompletion",
      navigateTo: "/milestone_profile",
      isComplete: isProfileComplete,
    },
    {
      label: "Instagram Verified",
      description: "Link social proof for better matching.",
      field: "isInstagramVerified",
      navigateTo: "/instagram_verification",
      isComplete: userProfile?.isInstagramVerified,
    },
    {
      label: "LinkedIn Verified",
      description: "Verify your professional background.",
      field: "isLinkedinVerified",
      navigateTo: "/linkedin_verification",
      isComplete: userProfile?.isLinkedinVerified,
    },
  ];

  const completedItems = profileItems.filter(
    ({ field, isComplete }) => userProfile?.[field] || isComplete
  ).length;
  const profileRating = Math.round((completedItems / profileItems.length) * 5);

  const handleNavigation = (navigateTo) => {
    if (navigateTo) navigate(navigateTo);
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={6}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              display={{ base: "flex", md: "none" }}
              color={colors.accentColor}
            />
            <Text 
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="bold"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
            >
              Complete Your Profile
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "2xl" }} 
            mx="auto"
          >
            {/* Profile Rating Gauge with Avatar */}
            <Box
              mb={8}
              p={8}
              borderRadius="xl"
              bg={colors.cardBg}
              textAlign="center"
              position="relative"
              borderWidth="1px"
              borderColor={colors.dividerColor}
            >
              <VStack spacing={6}>
                <Box position="relative" w={{ base: "150px", md: "180px" }} h={{ base: "150px", md: "180px" }}>
                  <CircularProgress
                    value={(profileRating / 5) * 100}
                    size="100%"
                    color={colors.accentColor}
                    thickness="8px"
                    capIsRound
                  />
                  <Avatar
                    size={{ base: "xl", md: "2xl" }}
                    src={userProfile?.profilePicURL}
                    name={userProfile?.fullName}
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    borderColor={colors.bgColor}
                    borderWidth="4px"
                  />
                </Box>
                {/* <Text fontSize={{ base: "lg", md: "xl" }} color={colors.textColor}>
                  Profile Strength: {profileRating}/5
                </Text> */}
              </VStack>
            </Box>

            {/* Profile Items List */}
            <Stack
              spacing={4}
              borderRadius="xl"
              overflow="hidden"
              borderWidth="1px"
              borderColor={colors.dividerColor}
            >
              {isLoading ? (
                <SkeletonText noOfLines={5} spacing={4} p={4} />
              ) : (
                profileItems.map(({ label, description, field, navigateTo, isComplete }) => (
                  <Box
                    key={field}
                    bg={colors.cardBg}
                    cursor={navigateTo ? "pointer" : "default"}
                    onClick={() => handleNavigation(navigateTo)}
                    _hover={navigateTo ? { bg: colors.hoverBgColor } : {}}
                    transition="all 0.2s"
                  >
                    <HStack p={4} spacing={4} justify="space-between">
                      <HStack spacing={4}>
                        <Box 
                          p={3}
                          bg={colors.iconBgColor}
                          borderRadius="full"
                          color={userProfile?.[field] || isComplete ? "green.500" : colors.alertIconColor}
                        >
                          {userProfile?.[field] || isComplete ? (
                            <FaCheckCircle size={20} />
                          ) : (
                            <FaExclamationCircle size={20} />
                          )}
                        </Box>
                        <Box>
                          <Text
                            fontWeight="bold"
                            fontSize={{ base: "md", md: "lg" }}
                            color={colors.textColor}
                          >
                            {label}
                          </Text>
                          <Text 
                            fontSize={{ base: "sm", md: "md" }}
                            color={colors.descriptionColor}
                          >
                            {description}
                          </Text>
                        </Box>
                      </HStack>
                      {navigateTo && <ChevronRightIcon color={colors.descriptionColor} boxSize={6} />}
                    </HStack>
                  </Box>
                ))
              )}

              {/* Promotions Item */}
              <Box
                bg={colors.cardBg}
                cursor="pointer"
                onClick={() => handleNavigation("/promotions")}
                _hover={{ bg: colors.hoverBgColor }}
                transition="all 0.2s"
              >
                <HStack p={4} spacing={4} justify="space-between">
                  <HStack spacing={4}>
                    <Box 
                      p={3}
                      bg={colors.iconBgColor}
                      borderRadius="full"
                      color={colors.accentColor}
                    >
                      <FaAward size={20} />
                    </Box>
                    <Box>
                      <Text
                        fontWeight="bold"
                        fontSize={{ base: "md", md: "lg" }}
                        color={colors.textColor}
                      >
                        Promotions
                      </Text>
                      <Text 
                        fontSize={{ base: "sm", md: "md" }}
                        color={colors.descriptionColor}
                      >
                        Unlock exclusive features and benefits.
                      </Text>
                    </Box>
                  </HStack>
                  <ChevronRightIcon color={colors.descriptionColor} boxSize={6} />
                </HStack>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ProfileStrengthPage;
